<template>
  <div class="home">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.png">
    </div>
    <div class="login portal">
      <div class="flexL" style="flex-direction: column; margin: auto;width: 520px;">
        <div style="padding-top: 5rem;">
          <div class="portal-logo">
            <img src="@/assets/images/login_logo.png" alt="logo">
          </div>
          <div class="portal-user">
            <div class="portal-user-info">
              <span>
                {{
                  userType === "User"
                      ? $t("user-data-type-user")
                      : userType === "Admin"
                          ? $t("user-data-type-admin")
                          : userType === "Manager"
                              ? $t("user-data-type-manager")
                              : "-"
                }}
              </span>
              <div class="portal-user-img">
                <img src="@/assets/images/ico_user.png">
              </div>
              <div class="portal-user-name">
                <h4>{{ name }}</h4>
                <h5>{{ loginId }}</h5>
              </div>
            </div>
            <div class="portal-user-info-btn">
              <button><router-link :to="{ name: 'profileEdit' }"><img src="@/assets/images/ic_profile_correction.png"></router-link></button>
              <button @click="logout">{{ $t("btn-logout") }}</button>
            </div>
          </div>
          <div class="portal-link">
            <div class="portal-link-box" @click="moveToDroneworkMain">
              <img src="@/assets/images/portal_01.png">
              <h4>{{ $t("portal-button-dronework") }}</h4>
            </div>
            <div class="portal-link-box" @click="moveToStationMain">
              <img src="@/assets/images/portal_04.png">
              <h4>{{ $t("portal-button-station") }}</h4>
            </div>
            <div class="portal-link-box" @click="moveToManagerMain" v-bind:class="[userType === 'Manager' || userType === 'Admin' ? 'portal-link-box':'portal-link-box no-portal']">
              <img src="@/assets/images/portal_02.png">
              <h4>{{ $t("portal-button-site") }}</h4>
            </div>
            <div class="portal-link-box" @click="moveToAdminMain" v-bind:class="[userType === 'Admin' ? 'portal-link-box':'portal-link-box no-portal']">
              <img src="@/assets/images/portal_03.png">
              <h4>{{ $t("portal-button-system") }}</h4>
            </div>
          </div>
          <div class="portal-notice">
            <h2>{{ $t("user-home-user-system-notice") }}</h2>
            <ul>
              <li v-for="(data, i) in noticeAppList" :key="i" @click="handleDetail(data.noticeAppId)">
                <h4><span v-if="isNewNotice(data.createdAt)">new</span>{{data.title}}</h4>
                <h5>{{ data ? moment(data.registerDate).format("YYYY.MM.DD HH:mm:SS") : "" }}</h5>
              </li>
              <li v-if="noticeAppTotal === 0">
                <h4>{{ $t("data-none-result") }}</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <transition name="fade">
      <div v-if="visible === 1" class="dim on">
        <SystemNoticeDetail @close="close" :noticeAppId="this.noticeAppId"/>
      </div>
    </transition>
  </div>
</template>
<style>
.login_bg{position: absolute; width: 100%;height: 100vh;}
.login_bg > img{width: 100%;height: 100vh; opacity: 0.2;}

.portal-logo{width: 350px;margin: auto;margin-bottom: 30px;}
.portal-logo > img{width: 100%;}

.portal-user{background: #ffffff54;display: flex;padding: 10px 15px;justify-content: space-between;align-items: center;border-radius: 5px;margin-bottom: 20px;}
.portal-user-info{display: flex;align-items: center;}
.portal-user-info > span{color: #fff;background: #3465FF;padding: 3px 20px;border-radius: 20px;}
.portal-user-img{margin: 0px 10px;width: 40px;}
.portal-user-img > img{width: 100%;}
.portal-user-name > h4{color: #fff;font-size: 1.5rem;font-weight: 400;}
.portal-user-name > h5{color: #fff;font-size: 1.2rem;font-weight: 400;}
.portal-user > .portal-user-info-btn > button:nth-child(1){border: 0;padding: 6px;background: #292a39cc;border-radius: 5px;margin-right: 5px;}
.portal-user > .portal-user-info-btn > button:nth-child(1) > a > img{width: 100%;height: 100%;}
.portal-user > .portal-user-info-btn > button:nth-child(1):hover{background: #3e4057cc;}
.portal-user > .portal-user-info-btn > button:nth-child(2){color: #fff;border: 0;padding: 2px 20px;background: #292a39cc;font-size: 1.4rem;font-weight: 400;border-radius: 5px;}
.portal-user > .portal-user-info-btn > button:nth-child(2):hover{background: #3e4057cc;}

.portal-link{display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;}
.portal-link-box{width: 22.3333%;background: #ffffff54;border-radius: 5px;padding: 10px;height: 110px;text-align: center;}
.portal-link-box > img{height: 40px;margin-top: 10px;}
.portal-link-box > h4{font-size: 1.4rem;color: #fff;font-weight: 400;margin-top: 15px;}
.portal-link-box:hover{cursor: pointer; outline: 1px solid #7eb2ff;background: #ffffff6e;}
.portal-link-box.no-portal{background: #1f232bc7;}
.portal-link-box.no-portal > img{display: none;}
.portal-link-box.no-portal > h4{display: none;}
.portal-link-box.no-portal:hover{background: #1f232bc7;outline: 0px solid #7eb2ff;cursor: auto;}

.portal-notice{background: #ffffff54;padding: 10px 15px;border-radius: 5px;}
.portal-notice > h2{color: #fff;font-size: 1.6rem;margin-bottom: 10px;padding-top: 5px;}
.portal-notice > ul{height: 160px;max-height: 160px;overflow-y: scroll;}
.portal-notice > ul::-webkit-scrollbar{width: 5px;}
.portal-notice > ul::-webkit-scrollbar-thumb{background-color: rgba(250, 250, 250, 0.73); border-radius: 4px;}
.portal-notice > ul::-webkit-scrollbar-track{border-radius: 2px; background: #ffffff00;border: 0px solid #f0f0f0;}
.portal-notice > ul > li{border-bottom: 1px solid #b3b3b3;padding-bottom: 10px;padding-top: 10px;display: flex;justify-content: space-between;align-items: center;}
.portal-notice > ul > li > h4{color: #fff;font-weight: 400;font-size: 1.3rem;}
.portal-notice > ul > li > h4 > span{font-size: 1.2rem;background: #ff862f;padding: 2px 5px;margin-right: 5px;border-radius: 5px;}
.portal-notice > ul > li > h5{font-size: 1.3rem;font-weight: 400;color: #fff;}

</style>
<script>
import { format } from "@/mixins/format";
import Footer from "@/components/Footer";
import lang from "@/mixins/lang";
import {mapState} from "vuex";
import Vue from "vue";
import VueCryptojs from 'vue-cryptojs'
import SystemNoticeDetail from "../../components/pop/SystemNoticeDetail";
import moment from "moment/moment";
import {fetchNoticeAppList} from "@/api/event";

Vue.use(VueCryptojs)

export default {
  components: { Footer, SystemNoticeDetail },
  mixins: [format, lang],
  data() {
    return {
      moment: moment,
      //시스템 공지
      visible: false,
      noticeAppList:[],
      noticeAppTotal: 0,
      noticeAppCurrentPage: 0,
      noticeAppPageSize: 20,
      noticeAppId: '',
    };
  },
  computed: {
    ...mapState({
      name : state => state.store.name,
      loginId : state => state.store.loginId,
      userType : state => state.store.userType,
    }),
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
      },
    },
  },
  watch : {
  },
  mounted() {
    this.getNoticeAppList();
    if(this.$route.query.currentLang != null) {
      this.currentLang = this.$route.query.currentLang;
    }
    this.accountId = this.$route.params.accountId;

    if(this.accountId === 'forest') {
      this.$refs.popupDiv.classList.add('model-open');
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    handleChecked(e) {
      this.autoLogin = e.target.checked;
    },
    getNoticeAppList(){
      let params = {
        pageNumber: this.noticeAppCurrentPage,
        pageSize: this.noticeAppPageSize,
        searchDate: moment(new Date()).format("YYYY-MM-DD"),
        status: "Used",
      };
      fetchNoticeAppList(params).then((res)=>{
        this.noticeAppList = res.data.data.content;
        this.noticeAppTotal = res.data.data.total;
      });
    },
    moveToDroneworkMain(){
      this.$router.push({ name: "liveListControlUser" });
    },
    moveToStationMain(){
      //TODO 추후 station 메인 페이지 연결
    },
    moveToManagerMain(){
      //TODO 추후 구현
    },
    moveToAdminMain(){
      if(this.userType === "Admin"){
        this.$router.push({ name: "overallAdmin" });
      }
    },
    handleDetail(noticeAppId) {
      this.noticeAppId = noticeAppId;
      this.visible = 1;
    },
    isNewNotice(createdAt) {
      const today = new Date();
      const noticeDate = new Date(createdAt);

      // 오늘 날짜와 공지 생성일을 비교하여 공지가 오늘 생성된 것인지 확인
      return today.toDateString() === noticeDate.toDateString();
    },
    logout() {
      let id = this.accountId;
      this.$store.dispatch("store/LOGOUT");
      this.$router.push(`/${id}/login`);
    },
  },
};

</script>
